import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Input, Select, TextArea, Button, Checkbox, Radio } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { navigate } from 'gatsby';

import Layout from '../components/Layout';
import { Config } from '../config';

export interface GetInvolvedPayload {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  reason: string;
  message: string;
  gdprConsent: boolean;
  newsletter: boolean;
}

const FIELDS = {
  firstName: 'firstName',
  lastName: 'lastName',
  phoneNumber: 'phoneNumber',
  email: 'email',
  reason: 'reason',
  message: 'message',
  gdprConsent: 'gdprConsent',
  newsletter: 'newsletter',
};

const selectOptions = [
  { key: 'learning', value: 'learning', text: 'Learning more about Expectation Church' },
  { key: 'joining_expectation', value: 'joining_expectation', text: 'Joining Expectation Church' },
];
type InterestedInOption = 'learning' | 'joining_expectation';
type State = 'submitting' | 'default';

const Connect = () => {
  const {
    watch,
    register,
    getValues,
    setValue,
    trigger,
    errors,
  } = useForm();
  const [state, setState] = useState<State>('default');

  const gdprConsentValue = watch(FIELDS.gdprConsent, null);
  const newsletterValue = watch(FIELDS.newsletter, null);
  const formName = 'contact';

  useEffect(() => {
    Object.keys(FIELDS).forEach((key) => register({ name: key }, { required: true }));
  }, []);

  useEffect(() => {
    // this is only needed while we're using the free heroku dyno
    fetch(Config.backend.url);
  }, []);

  useEffect(() => {
    // this is only needed while we're using the free heroku dyno
    fetch(Config.backend.url);
  }, [gdprConsentValue]);

  const onChange = async (_event, { name, value }) => {
    if ([FIELDS.gdprConsent, FIELDS.newsletter].includes(name)) {
      setValue(name, !getValues()[name]);
    } else {
      setValue(name, value);
    }

    await trigger(name);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const payload: GetInvolvedPayload = {} as GetInvolvedPayload;
    const formValues = getValues();

    Object.keys(FIELDS).forEach((key) => payload[key] = formValues[key]);

    try {
      const form = e.currentTarget;
      // send the data to the backend
      await fetch(`${Config.backend.url}/connect/get-involved`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      // post it to netlify as well so that the emails get sent
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `form-name=${form.name}&${Object.keys(payload).map(key => `${key}=${payload[key]}`).join('&')}`,
      });
      navigate(form.action);
    } catch (error) {
      alert('Failed to submit your form. Please check your network connection and try again');
      setState('default');
    }
  };


  return (
    <Layout className="connect">
      <article id="main">
        <header>
          <h1>
            Find Out More
          </h1>
        </header>
        <section>
          <p>
            Wherever you are on the journey of faith we'd love to help you get connected.
            Fill in the connect card & we'll be in touch.
          </p>
          <Form inverted data-netlify="true" className="form" name={formName} method="POST" action="/contact-success" onSubmit={onSubmit}>
            <input type="hidden" name="form-name" value="contact" />
            <div className="top">
              <Input
                error={Boolean(errors[FIELDS.firstName])}
                fluid
                onChange={onChange}
                placeholder="First Name"
                name="firstName"
                type="text"
                required
              />
              <Input
                error={Boolean(errors[FIELDS.lastName])}
                fluid
                onChange={onChange}
                placeholder="Last Name"
                name="lastName"
                type="text"
                required
              />
              <Input
                error={Boolean(errors[FIELDS.phoneNumber])}
                fluid
                onChange={onChange}
                placeholder="Phone Number"
                name="phoneNumber"
                type="tel"
                required
              />
              <Input
                error={Boolean(errors[FIELDS.email])}
                fluid
                onChange={onChange}
                placeholder="Email"
                name="email"
                type="email"
                required
              />
            </div>
            <div className="bottom">
              <Select
                fluid
                name="reason"
                placeholder="I am interested in"
                onChange={onChange}
                options={selectOptions}
                required
              />
              <TextArea
                name="message"
                placeholder="Message"
                label="Message"
                type="text"
                onChange={onChange}
                required
              />
              <Checkbox
                required
                checked={gdprConsentValue}
                onChange={onChange}
                name="gdprConsent"
                label="I consent to Expectation Church having the info provided in this form."
              />
              <Checkbox
                name="newsletter"
                checked={newsletterValue}
                label="I would like to receive updates from Expectation Church."
                onChange={onChange}
              />
              <Button
                inverted
                basic
                disabled={!gdprConsentValue || state === 'submitting'}
                type="submit"
                loading={state === 'submitting'}
              >
                Submit
              </Button>
            </div>
          </Form>
        </section>
      </article>
    </Layout>
  );
};

export default Connect;
